

/**ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
スマホのみTEL有効
 data-action="call" data-tel="00000000000"
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー**/
function spTelFunction() {
var agent = navigator.userAgent;
if (agent.search(/iPhone/) != -1 || agent.search(/iPad/) != -1 || agent.search(/iPod/) != -1 || agent.search(/Android/) != -1) {
 window.addEventListener('DOMContentLoaded', function () {
  if (!isPhone()) return;
  const actionTarget = document.querySelectorAll('span[data-action=call]');
  for (let i = 0; i < actionTarget.length; i++) {
   actionTarget[i].outerHTML = '<a href="tel:' + actionTarget[i].dataset.tel + '" style="color:inherit">' + actionTarget[i].outerHTML + '</a>';
  }
 });
 function isPhone() {
  return navigator.userAgent.indexOf('iPhone') > 0 || navigator.userAgent.indexOf('Android') > 0;
 }
}
}

/**ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
*スマホメニューボタン
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー**/
function spMenuBtn() {
document.querySelector('.sp_btn').addEventListener('click', function () {
document.documentElement.classList.toggle('spmenu-open');
});
 //メニューの中クリックしたらとじる
 document.querySelector('.spNavi .sp_btn').addEventListener('click', function () {
  document.documentElement.classList.remove('spmenu-open');
 });
}



/**ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
* globalNavi-linksのlistに子ulがあるならclassにhas-childを追加
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー**/
function addClassToChildListItems() {
// ul.globalNavi-links内の全てのli要素を取得
var liElements = document.querySelectorAll('ul.globalNavi-links > li');
// 各li要素について処理を行う
liElements.forEach(function(li) {
// li要素がul要素を子として持つ場合、クラスにhas-childを追加
if (li.querySelector('ul')) {
li.classList.add('has-child');
}
});
}




/**ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
SPメニューのアコーディオン
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー**/
function spmenuAccordion() {
/* slideUp, slideDown, slideToggle関数を定義 */
// 要素をスライドしながら非表示にする関数(jQueryのslideUpと同じ)

const slideUp = (el, duration = 300) => {
  el.style.height = el.offsetHeight + 'px';
  el.offsetHeight;
  el.style.transitionProperty = 'height, margin, padding';
  el.style.transitionDuration = duration + 'ms';
  el.style.transitionTimingFunction = 'ease';
  el.style.overflow = 'hidden';
  el.style.height = 0;
  el.style.paddingTop = 0;
  el.style.paddingBottom = 0;
  el.style.marginTop = 0;
  el.style.marginBottom = 0;
  setTimeout(() => {
   el.style.display = 'none';
   el.style.removeProperty('height');
   el.style.removeProperty('padding-top');
   el.style.removeProperty('padding-bottom');
   el.style.removeProperty('margin-top');
   el.style.removeProperty('margin-bottom');
   el.style.removeProperty('overflow');
   el.style.removeProperty('transition-duration');
   el.style.removeProperty('transition-property');
   el.style.removeProperty('transition-timing-function');
   el.classList.remove('is-open');
  }, duration);
 };
 
 // 要素をスライドしながら表示する関数(jQueryのslideDownと同じ)
 const slideDown = (el, duration = 300) => {
  el.classList.add('is-open');
  el.style.removeProperty('display');
  let display = window.getComputedStyle(el).display;
  if (display === 'none') {
   display = 'block';
  }
  el.style.display = display;
  let height = el.offsetHeight;
  el.style.overflow = 'hidden';
  el.style.height = 0;
  el.style.paddingTop = 0;
  el.style.paddingBottom = 0;
  el.style.marginTop = 0;
  el.style.marginBottom = 0;
  el.offsetHeight;
  el.style.transitionProperty = 'height, margin, padding';
  el.style.transitionDuration = duration + 'ms';
  el.style.transitionTimingFunction = 'ease';
  el.style.height = height + 'px';
  el.style.removeProperty('padding-top');
  el.style.removeProperty('padding-bottom');
  el.style.removeProperty('margin-top');
  el.style.removeProperty('margin-bottom');
  setTimeout(() => {
   el.style.removeProperty('height');
   el.style.removeProperty('overflow');
   el.style.removeProperty('transition-duration');
   el.style.removeProperty('transition-property');
   el.style.removeProperty('transition-timing-function');
  }, duration);
 };
 
 // 要素をスライドしながら交互に表示/非表示にする関数(jQueryのslideToggleと同じ)
 const slideToggle = (el, duration = 300) => {
  if (window.getComputedStyle(el).display === 'none') {
   return slideDown(el, duration);
  } else {
   return slideUp(el, duration);
  }
 };
 
 /*  DOM操作  *********************/
 
 // アコーディオンを全て取得
 const accordions = document.querySelectorAll('.spNavi-links,.sub-menu');
 // 取得したアコーディオンをArrayに変換(IE対策)
 const accordionsArr = Array.prototype.slice.call(accordions);
 //console.log(accordions);


 accordions.forEach(function (element) {
  let ulElements = element.getElementsByTagName('ul');


  //console.log(ulElements);

  Array.prototype.forEach.call(ulElements, function (element) {
   // console.log(element.parentNode.classList);

   element.parentNode.classList.add('menu-item-has-children');
   let aTag = element.parentNode.querySelector('a, span');
   aTag.classList.add('accordion');
   let aTagText = aTag.innerHTML;
   aTag.innerHTML = '<span class="accordionTitle">' + aTagText + '</span><span class="accordionBtn"><i class="fa-solid fa-plus"></i></span>';
  });
 });
 
 accordionsArr.forEach((accordion) => {
  // Triggerを全て取得
  const accordionTriggers = accordion.querySelectorAll('.menu-item-has-children');
  // TriggerをArrayに変換(IE対策)
  const accordionTriggersArr = Array.prototype.slice.call(accordionTriggers);
 
  accordionTriggersArr.forEach((trigger) => {

   //accordionBtnクリックイベントを付与
   trigger.querySelector('.accordionBtn').addEventListener(
    'click',
    (e) => {
  
     // デフォルトのリンク動作を無効化
     e.preventDefault();
     // イベントのバブリングを停止しています。これにより、親要素のクリックイベントが発生するのを防ぎます
     e.stopPropagation();

     // '.open'クラスを付与or削除
     trigger.classList.toggle('accordion-open');
     // 開閉させる要素を取得
     const content = trigger.querySelector('ul');
     // 要素を展開or閉じる
     slideToggle(content);
    },
    false
   );
  });
 });

}



/**ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
* back_to_top
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー**/
function backToTop() {
const pagetop = document.querySelector('.backtotop');
const pagetop_func = function () {
if (window.scrollY > 400) {
pagetop.classList.add('backtotop-visible');
} else {
pagetop.classList.remove('backtotop-visible');
}
};
window.addEventListener('load', pagetop_func);
window.addEventListener('scroll', pagetop_func);
pagetop.addEventListener('click', (e) => {
e.preventDefault();
window.scrollTo({
top: 0,
behavior: 'smooth',
});
});
}



/**ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
* smooth-scroll function
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー**/
function smoothScroll() {
const smoothScrollTrigger = document.querySelectorAll('a[href^="#"]');
for (let i = 0; i < smoothScrollTrigger.length; i++){

smoothScrollTrigger[i].addEventListener('click', (e) => {
e.preventDefault();
let href = smoothScrollTrigger[i].getAttribute('href');
let targetElement = document.getElementById(href.replace('#', ''));
const rect = targetElement.getBoundingClientRect().top;
const offset = window.pageYOffset;
const header = document.querySelector('#header');
const gap = header.offsetHeight;
const target = rect + offset - gap;

window.scrollTo({
 
top: target,
behavior: 'smooth',
});
});
}
}

/**ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
* autoAddTargetBlank function
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー**/
function autoAddTargetBlank() {
  const httpLinks = document.querySelectorAll('a[href^=http]');
  if(httpLinks.length > 0) {
    // サイトのホスト名を取得
    const hostname = location.hostname;
    httpLinks.forEach(function(elm) {
      if(!elm.getAttribute('href').includes(hostname)) {
        elm.setAttribute('target','_blank');
        elm.setAttribute('rel','noreferrer');
      }
    });
  }
}


/**ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
* 特定のURLのリンクにclassを限定公開アイコンを追加する
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー**/
function limitedLinkFunction() {
  const checkUrl = [
    'https://kuicplus.ofc.kobe-u.ac.jp/',
    'https://yakuhin.ofc.kobe-u.ac.jp/',
    'https://www.office.kobe-u.ac.jp/ksui-intra/'
  ];
  const links = document.querySelectorAll('a');
  links.forEach(link => {
    const href = link.getAttribute('href');
   
    if (href && checkUrl.some(url => href.includes(url))) {
      if (!link.querySelector('img')) {
        link.classList.add('limited-link');
      }
    }
  });
}



/**ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
* ulタグにiconuseクラスを追加する
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー**/
function addClassIconUse() {
  const ulElements = document.querySelectorAll('.editor-styles-wrapper ul');
  ulElements.forEach(ul => {
    ul.classList.add('iconuse');
  });
}





document.addEventListener('DOMContentLoaded', function() {
  spTelFunction();
  spMenuBtn();
  addClassToChildListItems();
  spmenuAccordion();
  backToTop();
  smoothScroll();
  autoAddTargetBlank();
  limitedLinkFunction();
  addClassIconUse();
});